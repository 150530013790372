<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="">
            <h4 class="title">Edit Stock Opname</h4>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/data-opname">Stock Opname </router-link>
                </li>
                <li class="breadcrumb-item" aria-current="page">
                  <router-link
                    :to="{ name: 'EditOpname', params: { kode: kode } }"
                    >Edit</router-link
                  >
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Hasil
                </li>
              </ol>
            </nav>
          </div>
          <div>
            <button
              class="btn btn-save"
              @click="save"
              :disabled="isSubmit || formData.detail.length == 0"
              v-if="!needConfirm || (needConfirm && checkAccess('inventory', 'approval_stock_opname'))"
            >
              <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
              {{ needConfirm ? 'Setujui Hasil' : 'Simpan' }}
            </button>
          </div>
        </div>

        <div class="card-table info-detail">
          <div class="row mt-3 align-items-center">
            <div class="col-12">
              <div class="title">Daftar Barang</div>
              <div class="sub-title">
                Rincian dari Daftar Barang yang diperlukan
              </div>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label class="">Penanggung Jawab :</label>
                <Select2
                  disabled
                  v-model="formData.karyawan_perintah"
                  :options="optionKaryawan"
                  placeholder="Pilih Penanggung Jawab"
                  @change="myChangeEvent($event)"
                  @select="mySelectEvent($event)"
                />
              </div>
            </div>

            <div class="col-6">
              <div class="form-group">
                <label class="">Pelaksana :</label>
                <Select2
                  disabled
                  v-model="formData.karyawan_pelaksana"
                  :options="optionKaryawan"
                  placeholder="Pilih Pelaksana"
                  @change="myChangeEvent($event)"
                  @select="mySelectEvent($event)"
                />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>Gudang :</label>
                <Select2
                  v-model="formData.id_gudang"
                  disabled
                  :options="OptionWerhouse"
                  placeholder="Pilih Gudang"
                  @change="formChange('id_gudang')"
                  @select="formChange('id_gudang')"
                  :class="{
                    'is-invalid': formError && formError.id_gudang,
                  }"
                />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>Departemen :</label>
                <Select2
                  v-model="id_divisi"
                  disabled
                  :options="OptionDepartement"
                  placeholder="Pilih Departemen"
                />
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table
              class="table table-bordered table-striped display"
              id="tableResult"
              style="width: 100%"
            >
              <thead>
                <tr>
                  <th rowspan="2">Kode</th>
                  <th rowspan="2">Nama Barang</th>
                  <th colspan="3" class="text-center">Qty</th>
                  <th rowspan="2">Satuan</th>
                  <th rowspan="2">Alasan</th>
                  <th rowspan="2" style="width: 300px" v-if="needConfirm">Akun</th>
                </tr>
                <tr>
                  <th style="width: 8%">Sistem</th>
                  <th style="width: 8%">Hasil</th>
                  <th style="width: 8%">Selisih</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, index) in formData.detail" :key="index">
                  <td>{{ value.kode_barang }}</td>
                  <td>{{ value.nama_barang }}</td>
                  <td>{{ value.jumlah ? value.jumlah : "0" }}</td>
                  <td class="px-2">
                    <input
                      type="text"
                      class="form-control"
                      style="border: 1px solid #b3a9a9; background: white"
                      placeholder="Masukkan Hasil"
                      v-maska="'#################'"
                      @keyup="setHasil(index)"
                      v-model="formData.detail[index].hasil"
                      v-if="!needConfirm"
                    />
                    {{ needConfirm ? formData.detail[index].hasil : '' }}
                  </td>
                  <td>{{ formData.detail[index].selisih }}</td>
                  <td>{{ value.nama_satuan }}</td>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      style="border: 1px solid #b3a9a9; background: white"
                      placeholder="Masukkan Alasan"
                      v-model="formData.detail[index].alasan"
                      :class="{
                        'is-invalid': formData.detail[index].selisih && formData.detail[index].selisih != 0 && formData.detail[index].selisih != '0',
                      }"
                      v-if="!needConfirm"
                    />
                    {{ needConfirm ? formData.detail[index].alasan ? formData.detail[index].alasan : '-' : '' }}
                  </td>
                  <td v-if="needConfirm">
                    <Select2
                      v-model="formData.detail[index].akun_selisih"
                      v-if="formData.detail[index].selisih && formData.detail[index].selisih != 0 && formData.detail[index].selisih != '0'"
                      :options="optionAkun"
                      placeholder="Pilih Akun"
                      @change="myChangeEvent($event)"
                      @select="mySelectEvent($event)"
                      :class="{
                        'is-invalid':
                          !formData.detail[index].akun_selisih,
                      }"
                      :settings="{
                        templateResult: formatState,
                      }"
                    />
                    <span v-else>-</span>
                  </td>
                </tr>
                <tr v-if="formData.detail.length == 0">
                  <td colspan="7" class="text-center">Tidak ada data</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../../../components/Sidebar.vue";
import NavbarDashboard from "../../../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";
// import Datepicker from "vue3-date-time-picker";
import { ref } from "vue";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";
import {
  get_StockOpnamePraSave,
  post_StockOpnameSaveHasil,
} from "../../../../../actions/barang/stockOpname";

import { maska } from "maska";
import { cksClient, showAlert, checkModuleAccess } from "../../../../../helper";
import { get_ListDivisi } from "../../../../../actions/master";
import { get_AkunList } from '../../../../../actions/akun_perkiraan';

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    // Datepicker,
    // TableLite
  },

  directives: {
    maska,
  },

  data() {
    return {
      checkAccess: checkModuleAccess,
      optionKaryawan: [],
      OptionWerhouse: [],
      OptionDepartement: [],
      optionAkun: [],
      kode: this.$route.params.kode ? this.$route.params.kode : "",
      id_company: cksClient().get("_account").id_company,
      accessDepartement: cksClient().get("_account").akses_departement,
      formData: {
        kode: this.$route.params.kode ? this.$route.params.kode : "",
        karyawan_pelaksana: "",
        karyawan_perintah: "",
        id_gudang: "",
        status_hasil: "waiting",
        detail: [],
      },
      id_divisi: "",
      isSubmit: false,
      needConfirm: false,
    };
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  mounted() {
    // $("#tableResult").DataTable({
    //   info: false,
    // });
  },

  created() {
    this.getAkun()
    this.getDivisi();
    this.getData();
  },

  methods: {
    getDivisi() {
      get_ListDivisi({ id_company: this.id_company }, (res) => {
        const { list } = res;
        this.OptionDepartement = [];
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const element = list[key];
            this.OptionDepartement.push(element);
          }
        }
      });
    },
    async checkData() {
      var check = true;
        if (this.needConfirm) {
          for (const key in this.formData.detail) {
          if (Object.hasOwnProperty.call(this.formData.detail, key)) {
            const element = this.formData.detail[key];
            if (element.selisih && element.selisih != '0' && element.selisih != 0) {
              if (!element.akun_selisih) {
                check = false;
                break;
              }
            }
          }
        }      
      } else {
        for (const key in this.formData.detail) {
          if (Object.hasOwnProperty.call(this.formData.detail, key)) {
            const element = this.formData.detail[key];
            if (element.selisih && element.selisih != '0' && element.selisih != 0) {
              if (!element.alasan) {
                check = false;
                break;
              }
            }
          }
        }
      }

      return check;
    },
    async save() {
      var check = await this.checkData();
      if (check) {
        showAlert(this.$swal, {
          title: "PERHATIAN",
          msg: this.formData.status_hasil == "waiting" ?
          "Apakah anda yakin akan menyimpan hasil, semua data akan menunggu persetujuan terlebih dahulu sebelum disimpan?" 
          : "Apakah anda yakin akan menyimpan hasil, semua data akan tersimpan secara otomatis dan tidak bisa diubah?",
          showCancelButton: true,
          confirmButtonText: "Ya, Simpan hasil",
          cancelButtonText: "Batal",
          onSubmit: () => {
            this.isSubmit = true;
            post_StockOpnameSaveHasil(
              this.formData,
              () => {
                this.isSubmit = false;
                showAlert(this.$swal, {
                  title: "BERHASIL!",
                  msg: "Hasil stock opname berhasil dicatat",
                  onSubmit: () => {
                    this.$router.push({
                      name: "DetailOpname",
                      params: {
                        kode: this.kode,
                      },
                    });
                  },
                });
              },
              () => {
                this.isSubmit = false;
                showAlert(this.$swal, {
                  title: "GAGAL!",
                  msg: "Terjadi kesalahan, silakan ulangi kembali",
                  showCancelButton: true,
                  showConfirmButton: false,
                });
              }
            );
          },
        });
      }
    },
    setHasil(key) {
      var jumlah = parseInt(this.formData.detail[key].jumlah)
        ? parseInt(this.formData.detail[key].jumlah)
        : 0;
      var hasil = parseInt(this.formData.detail[key].hasil)
        ? parseInt(this.formData.detail[key].hasil)
        : 0;
      var selisih = Math.abs(jumlah - hasil);
      this.formData.detail[key].selisih = selisih;
    },
    getData() {
      get_StockOpnamePraSave(
        {
          kode: this.kode,
          isPersediaan: false,
        },
        (res) => {
          var { data, karyawan, detail, gudang } = res;
          this.optionKaryawan = karyawan;
          this.OptionWerhouse = gudang;
          this.formData.id_gudang = data.id_gudang;
          this.formData.karyawan_pelaksana = data.karyawan_pelaksana;
          this.formData.karyawan_perintah = data.karyawan_perintah;
          this.formData.detail = detail;
          this.id_divisi = data.id_divisi;
          if (data.status_hasil == 'waiting') {
            this.formData.status_hasil = 'confirm';
            this.needConfirm = true
          }
        },
        () => {
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Data tidak ditemukan",
            showCancelButton: true,
            showConfirmButton: false,
            onCancel: () => {
              this.$router.push({
                name: "DataOpname",
              });
            },
          });
        }
      );
    },

    formatState(state) {
      if (!state.id) {
        return state.text;
      }
      var padding =
        state.sub_level == 2 ? "pl-3" : state.sub_level == 1 ? "pl-2" : "";
      var $state = $(
        '<div class="' +
          padding +
          '">' +
          state.text +
          '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
          state.no +
          "<div>" +
          state.type +
          "</div></div></div>"
      );
      return $state;
    },

    getAkun(tipe = "") {
      var id_divisi =
        this.accessDepartement && this.formData.id_divisi
          ? this.formData.id_divisi
          : "";
      get_AkunList(
        {
          id_company: this.id_company,
          tipe: tipe,
          id_divisi: id_divisi,
        },
        (res) => {
          var data = [];
          const { list } = res;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              data.push({
                id: element.id,
                text: `${element.nama}`,
                no: element.id,
                type: element.tipe,
                sub_level: parseInt(element.sub_level),
                disabled:
                  parseInt(element.max_subLevel) === parseInt(element.sub_level)
                    ? false
                    : true,
              });
            }
          }
          this.optionAkun = data;
        }
      );
    },
  },
};
</script>

<style scoped src="../../../../../assets/css/style.css"></style>

<style scoped src="../../../../../assets/css/custom.css"></style>

<style scoped>
.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

table input {
  border: none;
  padding: 7px;
}

table input:focus {
  background-color: transparent !important;
}

table tr:nth-child(2) th {
  border-radius: 0px !important;
}

tr:first-child th {
  vertical-align: middle;
}

.info-detail .title {
  font-size: 18px;
  font-weight: 500;
}

.info-detail .sub-title {
  color: #c6c6c6;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}
</style>
